<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <!-- Counter Widgets -->
    <a-row :gutter="24">
      <a-col
        :span="24"
        :lg="12"
        :xl="6"
        class="mb-24"
        v-for="(stat, index) in stats"
        :key="index"
      >
        <!-- Widget 1 Card -->
        <WidgetCounter
          :title="lang == 'uz' ? stat.title : stat.title_ru"
          :value="stat.value"
          :prefix="stat.prefix"
          :suffix="stat.suffix"
          :icon="stat.icon"
          :status="stat.status"
        ></WidgetCounter>
        <!-- / Widget 1 Card -->
      </a-col>
    </a-row>
    <!-- / Counter Widgets -->

    <!-- Charts -->
    <a-row :gutter="24" type="flex" align="stretch">
      <a-col :span="24" :lg="10" class="mb-24">
        <!-- Active Users Card -->
        <CardBarChart></CardBarChart>
        <!-- Active Users Card -->
      </a-col>
      <a-col :span="24" :lg="14" class="mb-24">
        <!-- Sales Overview Card -->
        <CardLineChart></CardLineChart>
        <!-- / Sales Overview Card -->
      </a-col>
    </a-row>
    <!-- / Charts -->

    <!-- Table & Timeline -->
    <a-row :gutter="24" type="flex" align="stretch">
      <!-- Table -->
      <a-col :span="24" :lg="16" class="mb-24">
        <!-- Projects Table Card -->
        <CardProjectTable
          :data="tableData"
          :columns="lang == 'uz' ? tableColumns : tableColumnsRu"
        ></CardProjectTable>
        <!-- / Projects Table Card -->
      </a-col>
      <!-- / Table -->

      <!-- Timeline -->
      <a-col :span="24" :lg="8" class="mb-24">
        <!-- Orders History Timeline Card -->
        <CardOrderHistory></CardOrderHistory>
        <!-- / Orders History Timeline Card -->
      </a-col>
      <!-- / Timeline -->
    </a-row>
    <!-- / Table & Timeline -->

    <!-- <a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :xl="14" class="mb-24">
				<CardInfo></CardInfo>
			</a-col>
			<a-col :span="24" :xl="10" class="mb-24">
				<CardInfo2></CardInfo2>
			</a-col>
		</a-row> -->
  </div>
</template>

<script>
// Bar chart for "Active Users" card.
import CardBarChart from "../components/Cards/CardBarChart";

// Line chart for "Sales Overview" card.
import CardLineChart from "../components/Cards/CardLineChart";

// Counter Widgets
import WidgetCounter from "../components/Widgets/WidgetCounter";

// "Projects" table component.
import CardProjectTable from "../components/Cards/CardProjectTable";

// Order History card component.
import CardOrderHistory from "../components/Cards/CardOrderHistory";

// Information card 1.
import CardInfo from "../components/Cards/CardInfo";

// Information card 2.
import CardInfo2 from "../components/Cards/CardInfo2";

// Counter Widgets stats
const stats = [
  {
    title: "XODIMLAR",
    title_ru: "ПЕРСОНАЛ",
    value: 473,
    prefix: "",
    suffix: "+12",
    icon: `
				<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="none">
					<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
					<path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-96 55.2C54 332.9 0 401.3 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-81-54-149.4-128-171.1V362c27.6 7.1 48 32.2 48 62v40c0 8.8-7.2 16-16 16H336c-8.8 0-16-7.2-16-16s7.2-16 16-16V424c0-17.7-14.3-32-32-32s-32 14.3-32 32v24c8.8 0 16 7.2 16 16s-7.2 16-16 16H256c-8.8 0-16-7.2-16-16V424c0-29.8 20.4-54.9 48-62V304.9c-6-.6-12.1-.9-18.3-.9H178.3c-6.2 0-12.3 .3-18.3 .9v65.4c23.1 6.9 40 28.3 40 53.7c0 30.9-25.1 56-56 56s-56-25.1-56-56c0-25.4 16.9-46.8 40-53.7V311.2zM144 448c13.3 0 24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24s10.7 24 24 24z"/>
				</svg>`,
  },
  {
    title: "BEMORLAR",
    title_ru: "ПАЦИЕНТЫ",
    value: 18170,
    prefix: "",
    suffix: "+100",
    icon: `
				<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 576 512">
					<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
					<path d="M48 0C21.5 0 0 21.5 0 48V256H144c8.8 0 16 7.2 16 16s-7.2 16-16 16H0v64H144c8.8 0 16 7.2 16 16s-7.2 16-16 16H0v80c0 26.5 21.5 48 48 48H265.9c-6.3-10.2-9.9-22.2-9.9-35.1c0-46.9 25.8-87.8 64-109.2V271.8 48c0-26.5-21.5-48-48-48H48zM152 64h16c8.8 0 16 7.2 16 16v24h24c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H184v24c0 8.8-7.2 16-16 16H152c-8.8 0-16-7.2-16-16V152H112c-8.8 0-16-7.2-16-16V120c0-8.8 7.2-16 16-16h24V80c0-8.8 7.2-16 16-16zM512 272c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80s80-35.8 80-80zM288 477.1c0 19.3 15.6 34.9 34.9 34.9H541.1c19.3 0 34.9-15.6 34.9-34.9c0-51.4-41.7-93.1-93.1-93.1H381.1c-51.4 0-93.1 41.7-93.1 93.1z"/>
				</svg>`,
  },
  {
    title: "DAVOLANGANLAR",
    title_ru: "ТЕ, КОГО ЛЕЧИЛИ",
    value: 17850,
    prefix: "+",
    status: "success",
    suffix: "+59",
    icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z" fill="#111827"/>
						</svg>`,
  },
  {
    title: "DAVOLANAYOTGANLAR",
    title_ru: "ТЕ, КТО ПОЛУЧАЕТ ЛЕЧЕНИЯ",
    value: 300,
    prefix: "+",
    suffix: "+48",
    icon: `
					<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 640 512">
						<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
						<path d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zM176 288c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"/>
					</svg>`,
  },
];

// "Projects" table list of columns and their properties.
const tableColumns = [
  {
    title: "KASALLIKLAR",
    dataIndex: "company",
    scopedSlots: { customRender: "company" },
    width: 300,
  },
  {
    title: "BEMORLAR",
    dataIndex: "members",
    scopedSlots: { customRender: "members" },
  },
  {
    title: "DAVOLANAYOTGANLAR",
    dataIndex: "budget",
    class: "font-bold text-muted text-sm",
  },
  {
    title: "DAVOLANGANLAR",
    scopedSlots: { customRender: "completion" },
    dataIndex: "completion",
  },
];
const tableColumnsRu = [
  {
    title: "БОЛЕЗНИ",
    dataIndex: "company",
    scopedSlots: { customRender: "company" },
    width: 300,
  },
  {
    title: "ПАЦИЕНТЫ",
    dataIndex: "members",
    scopedSlots: { customRender: "members" },
  },
  {
    title: "ТЕ, КОГО ЛЕЧИЛИ",
    dataIndex: "budget",
    class: "font-bold text-muted text-sm",
  },
  {
    title: "ТЕ, КТО ПОЛУЧАЕТ ЛЕЧЕНИЯ",
    scopedSlots: { customRender: "completion" },
    dataIndex: "completion",
  },
];

// "Projects" table list of rows and their properties.
const tableData = [
  {
    key: "1",
    company: {
      name: "Yurak ishemik kasalligi",
      name_ru: "Ишемическая болезнь сердца",
      logo: "images/heart.png",
    },
    members: [
      "images/face-1.jpg",
      "images/face-4.jpg",
      "images/face-2.jpg",
      "images/face-3.jpg",
    ],
    budget: "210",
    completion: 60,
  },
  {
    key: "2",
    company: {
      name: "Tug’ma yurak porogi",
      name_ru: "Врожденный порок сердца",
      logo: "images/heart2.png",
    },
    members: ["images/face-4.jpg", "images/face-3.jpg"],
    budget: "105",
    completion: 75,
  },
  {
    key: "3",
    company: {
      name: "Yurak yetishmovchiligi",
      name_ru: "Сердечная недостаточность",
      logo: "images/heart3.png",
    },
    members: ["images/face-1.jpg", "images/face-2.jpg", "images/face-3.jpg"],
    budget: "58",
    completion: {
      label: "100",
      status: "success",
      value: 100,
    },
  },
  {
    key: "4",
    company: {
      name: "Yurak ritmi buzilishi",
      name_ru: "Нарушение сердечного ритма",
      logo: "images/heart4.png",
    },
    members: ["images/face-1.jpg", "images/face-2.jpg"],
    budget: "280",
    completion: {
      label: "100",
      status: "success",
      value: 100,
    },
  },
  {
    key: "5",
    company: {
      name: "Orttirilgan yurak porogi",
      name_ru: "Приобретенный сердечный порошок",
      logo: "images/heart5.png",
    },
    members: [
      "images/face-1.jpg",
      "images/face-4.jpg",
      "images/face-2.jpg",
      "images/face-3.jpg",
    ],
    budget: "116",
    completion: 80,
  },
  // {
  // 	key: '6',
  // 	company: {
  // 		name: 'Redesign Online Store',
  // 		logo: 'images/logos/logo-invision.svg',
  // 	},
  // 	members: [ "images/face-1.jpg", "images/face-4.jpg", "images/face-3.jpg", ],
  // 	budget: '$2,000',
  // 	completion: {
  // 		label: 'Cancelled',
  // 		status: 'exception',
  // 		value: 100,
  // 	},
  // },
];

export default {
  components: {
    CardBarChart,
    CardLineChart,
    WidgetCounter,
    CardProjectTable,
    CardOrderHistory,
    CardInfo,
    CardInfo2,
  },
  data() {
    return {
      // Associating table data with its corresponding property.
      tableData,
      tableColumnsRu,
      // Associating table columns with its corresponding property.
      tableColumns,
      lang: null,
      // Counter Widgets Stats
      stats,
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
  },
};
</script>

<style lang="scss">
</style>